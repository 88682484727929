import React, {useRef} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container} from "../components/Container";
import {Section} from "../components/HomePage/styles";
import {SectionTitle} from "../components/SectionTitle/styles";
import {colors} from "../const/colors";
import GFQuestionare from "../components/GFQuestionare/GFQuestionare";
import "survey-react/modern.css"
import localData from "../localization/questionare.json";

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const IndexPage = ({location, data, pageContext}) => {
    const topElement = useRef(null)
    const { lang } = pageContext
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    return <Layout location={location} lang={lang} flags={flags}>
        <SEO
            title={ meta?.metaTitle || metaStatic.metaTitle}
            description={ meta?.metaDescription || metaStatic.metaDescription}
            keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
        />
        <Section style={{background:colors.lightBg}}>
            <div ref={topElement} className="hidden" />
            <Container>
                <SectionTitle color={colors.greenColor} align="center">Questionnaire</SectionTitle>
                <GFQuestionare topElement={topElement} />
            </Container>
        </Section>
    </Layout>
}

export default IndexPage
