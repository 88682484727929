export const questions = {
    pages: [
        {
            name: "page1",
            elements: [
                {
                    type: "radiogroup",
                    name: "are_you_ready_for_digital_transformation",
                    title: "Are you ready for digital transformation?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "Maybe",
                            text: "Maybe"
                        },
                        {
                            value: "Yes",
                            text: "Yes"
                        },
                        {
                            value: "Can't wait",
                            text: "Can't wait"
                        }
                    ]
                }
            ]
        },
        {
            name: "page2",
            elements: [
                {
                    type: "radiogroup",
                    name: "what_brought_you_here",
                    title: "What brought you here?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "my_curiosity",
                            text: "My curiosity"
                        },
                        {
                            value: "i_am_looking_for_ad_digital_solution_for_my_business",
                            text: "I am looking for an digital solution for my business"
                        },
                        {
                            value: "someone_referred_green_flamingo_to_me",
                            text: "Someone referred Green flamingo to me"
                        },
                        {
                            value: "i_misclicked_but_this_seems_interesting",
                            text: "I misclicked, but this seems interesting"
                        }
                    ]
                }
            ]
        },
        {
            name: "page3",
            elements: [
                {
                    type: "radiogroup",
                    name: "what_type_of_service_do_you_need",
                    title: "What type of service do you need ?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "i_might_need_a_website_in_the_future",
                            text: "I might need a website in the future"
                        },
                        {
                            value: "i_am_interested_in_knowing_more_about_your_ecommerce_solution",
                            text: "I am interested in knowing more about your e-commerce solution"
                        },
                        {
                            value: "i_would_like_to_know_if_my_business_needs_a_web_app",
                            text: "I would like to know if my business needs a web app"
                        },
                        {
                            value: "i_would_like_to_know_if_my_business_needs_a_mobile_app",
                            text: "I would like to know if my business needs a mobile app"
                        },
                        {
                            value: "i_am_exploring_the_idea_of_working_on_my_brand_identity",
                            text: "I am exploring the idea of working on my brand identity"
                        },
                        {
                            value: "i_would_like_to_know_how_digital_marketing_can_help_my_brand",
                            text: "I would like to know how digital marketing can help my brand"
                        }
                    ]
                }
            ],
            visibleIf: "{what_brought_you_here} = 'my_curiosity'"
        },
        {
            name: "page4",
            elements: [
                {
                    type: "radiogroup",
                    name: "what_type_of_service_do_you_need_1",
                    title: "What type of service do you need ?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "i_need_website_for_my_business",
                            text: "I need website for my business"
                        },
                        {
                            value: "i_urgently_need_a_modern_and_scalable_ecommerce_solution",
                            text: "I urgently need a modern and scalable e-commerce solution"
                        },
                        {
                            value: "i_need_to_implement_my_business_idea_through_a_custom_software_on_the_cloud",
                            text: "I need to implement my business idea through a custom software on the cloud"
                        },
                        {
                            value: "i_need_to_personalize_my_business_concept_and_implement_it_via_mobile_app",
                            text: "I need to personalize my business concept and implement it via mobile app"
                        },
                        {
                            value: "i_need_brand_identity_for_my_business",
                            text: "I need brand identity for my business"
                        },
                        {
                            value: "i_want_to_improve_my_companys_digital_presence_to_reach_my_target_audience",
                            text: "I want to improve my company's digital presence to reach my target audience"
                        }
                    ]
                }
            ],
            visibleIf: "{what_brought_you_here} = 'i_am_looking_for_ad_digital_solution_for_my_business'"
        },
        {
            name: "page5",
            elements: [
                {
                    type: "radiogroup",
                    name: "what_type_of_service_do_you_need_2",
                    title: "What type of service do you need ?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "i_want_my_website_done_by_green_flamingo",
                            text: "I want my website done by Green Flamingo"
                        },
                        {
                            value: "i_want_green_flamingo_to_make_me_an_online_shop",
                            text: "I want Green Flamingo to make me an online shop"
                        },
                        {
                            value: "i_want_green_flamingo_to_create_a_custombuilt_software_for_my_business",
                            text: "I want Green Flamingo to create a custom-built software for my business"
                        },
                        {
                            value: "i_want_a_mobile_app_for_my_business_developed_by_green_flamingo",
                            text: "I want a mobile app for my business developed by Green Flamingo"
                        },
                        {
                            value: "i_want_green_flamingo_to_work_on_my_branding_and_help_me_developimprove_it",
                            text: "I want Green Flamingo to work on my branding and help me develop/improve it"
                        },
                        {
                            value: "i_want_to_work_with_green_flamingo_on_developing_my_digital_marketing_activities",
                            text: "I want to work with Green Flamingo on developing my digital marketing activities"
                        }
                    ]
                }
            ],
            visibleIf: "{what_brought_you_here} = 'someone_referred_green_flamingo_to_me'"
        },
        {
            name: "page6",
            elements: [
                {
                    type: "radiogroup",
                    name: "what_type_of_service_do_you_need_3",
                    title: "What type of service do you need ?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "website_that_loads_under_400ms_lets_see",
                            text: "Website that loads under 400ms?! let's see"
                        },
                        {
                            value: "a_custom_made_ecommerce_website_that_can_do_whatever_i_need_sign_me_up",
                            text: "A custom made e-commerce website that can do whatever i need? sign me up!"
                        },
                        {
                            value: "i_want_to_make_a_spaceship_and_make_my_business_fly_to_mars",
                            text: "I want to make a spaceship and make my business fly to Mars"
                        },
                        {
                            value: "i_want_to_control_my_rocket_over_a_mobile_device_while_im_drinking_coffee",
                            text: "I want to control my rocket over a mobile device while im drinking coffee"
                        },
                        {
                            value: "i_would_like_my_brand_to_be_recognized_on_reddit_like_im_brad_pitt",
                            text: "I would like my brand to be recognized on reddit like I'm Brad Pitt"
                        },
                        {
                            value: "i_would_like_for_people_to_see_how_awesome_my_business_is_on_social_media",
                            text: "I would like for people to see how awesome my business is on social media"
                        }
                    ]
                }
            ],
            visibleIf: "{what_brought_you_here} = 'i_misclicked_but_this_seems_interesting'"
        },
        {
            name: "website",
            elements: [
                {
                    type: "radiogroup",
                    name: "how_frequently_do_you_need_to_update_your_website",
                    title: "How frequently do you need to update your website?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "i_dont_need_updates",
                            text: "I don't need updates"
                        },
                        {
                            value: "1_5_per_month",
                            text: "1-5 per month"
                        },
                        {
                            value: "5_20_per_month",
                            text: "5-20 per month"
                        },
                        {
                            value: "i_dont_know",
                            text: "i don't know"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "specific_issue",
                    title: "Do you need to resolve any specific issue aside from presenting your business to your customers?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                }
            ],
            visibleIf: "{what_type_of_service_do_you_need} = 'i_might_need_a_website_in_the_future' or {what_type_of_service_do_you_need_1} = 'i_need_website_for_my_business' or {what_type_of_service_do_you_need_2} = 'i_want_my_website_done_by_green_flamingo' or {what_type_of_service_do_you_need_3} = 'website_that_loads_under_400ms_lets_see'"
        },
        {
            name: "shop",
            elements: [
                {
                    type: "radiogroup",
                    name: "what_is_your_monthly_turnover",
                    title: "What is your monthly turnover?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "lest_than_5000_euros",
                            text: "< 5000€"
                        },
                        {
                            value: "from_5000_to_10000_euros",
                            text: "5.000-10.000€"
                        },
                        {
                            value: "10000_and_more_euros",
                            text: "10.000€ +"
                        },
                        {
                            value: "dont_want_to_share",
                            text: " don't want to share"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "specific_sales_issue",
                    title: "Do you need to resolve any specific issue aside from creating a sales channel for your products?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                }
            ],
            visibleIf: "{what_type_of_service_do_you_need} = 'i_am_interested_in_knowing_more_about_your_ecommerce_solution' or {what_type_of_service_do_you_need_1} = 'i_urgently_need_a_modern_and_scalable_ecommerce_solution' or {what_type_of_service_do_you_need_2} = 'i_want_green_flamingo_to_make_me_an_online_shop' or {what_type_of_service_do_you_need_3} = 'a_custom_made_ecommerce_website_that_can_do_whatever_i_need_sign_me_up'"
        },
        {
            name: "app",
            elements: [
                {
                    type: "comment",
                    name: "what_would_be_the_real_life_problem_that_you_need_to_resolvetransmit_to_the_digital_world",
                    title: "What would be the real life problem that you need to resolve/transmit to the digital world?",
                    hideNumber: true,
                    isRequired: true
                }
            ],
            visibleIf: "{what_type_of_service_do_you_need} anyof ['other', 'item4', 'i_would_like_to_know_if_my_business_needs_a_web_app', 'i_would_like_to_know_if_my_business_needs_a_mobile_app'] or {what_type_of_service_do_you_need_1} anyof ['other', 'item4', 'i_need_to_implement_my_business_idea_through_a_custom_software_on_the_cloud', 'i_need_to_personalize_my_business_concept_and_implement_it_via_mobile_app'] or {what_type_of_service_do_you_need_2} anyof ['other', 'item4', 'i_want_green_flamingo_to_create_a_custombuilt_software_for_my_business', 'i_want_a_mobile_app_for_my_business_developed_by_green_flamingo'] or {what_type_of_service_do_you_need_3} anyof ['other', 'item4', 'i_want_to_make_a_spaceship_and_make_my_business_fly_to_mars', 'i_want_to_control_my_rocket_over_a_mobile_device_while_im_drinking_coffee'] or {specific_issue} = 'yes' or {specific_sales_issue} = 'yes'"
        },
        {
            name: "visual_content",
            elements: [
                {
                    type: "checkbox",
                    name: "do_you_need_visualtextual_content_",
                    title: " Do you need visual/textual content ?",
                    hideNumber: true,
                    choices: [
                        {
                            value: "i_need_new_images_illustrations_and_infographics",
                            text: "I need new images, illustrations and infographics"
                        },
                        {
                            value: "i_need_new_videos_animations_and_giffs",
                            text: "I need new videos, animations and giffs"
                        },
                        {
                            value: "i_need_new_text",
                            text: "I need new text"
                        }
                    ]
                }
            ],
            visibleIf: "{what_type_of_service_do_you_need} anyof ['other', 'item6', 'i_am_exploring_the_idea_of_working_on_my_brand_identity', 'i_would_like_to_know_how_digital_marketing_can_help_my_brand'] or {what_type_of_service_do_you_need_1} anyof ['other', 'item6', 'i_need_brand_identity_for_my_business', 'i_want_to_improve_my_companys_digital_presence_to_reach_my_target_audience'] or {what_type_of_service_do_you_need_2} anyof ['other', 'item6', 'i_want_green_flamingo_to_work_on_my_branding_and_help_me_developimprove_it', 'i_want_to_work_with_green_flamingo_on_developing_my_digital_marketing_activities'] or {what_type_of_service_do_you_need_3} anyof ['other', 'item6', 'i_would_like_my_brand_to_be_recognized_on_reddit_like_im_brad_pitt', 'i_would_like_for_people_to_see_how_awesome_my_business_is_on_social_media'] or {specific_issue} anyof ['no', 'i_am_not_sure'] or {specific_sales_issue} anyof ['no', 'i_am_not_sure'] or {what_would_be_the_real_life_problem_that_you_need_to_resolvetransmit_to_the_digital_world} notempty"
        },
        {
            name: "branding",
            elements: [
                {
                    type: "radiogroup",
                    name: "do_you_need_a_product_catalogue",
                    title: "Do you need a product catalogue?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "do_you_need_business_cards",
                    title: "Do you need business cards?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "do_you_need_custom_email_templates",
                    title: "Do you need custom email templates?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "do_you_need_a_logo",
                    title: "Do you need a logo?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                }
            ]
        },
        {
            name: "marketing",
            elements: [
                {
                    type: "radiogroup",
                    name: "do_you_need_instagram_feed_design",
                    title: "Do you need instagram feed design",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "do_you_need_social_media_management",
                    title: "Do you need social media management?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                },
                {
                    type: "radiogroup",
                    name: "do_you_need_an_explainer_video",
                    title: "Do you need an explainer video?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        },
                        {
                            value: "i_am_not_sure",
                            text: "I am not sure"
                        }
                    ]
                }
            ]
        },
        {
            name: "web_shop_app",
            elements: [
                {
                    type: "radiogroup",
                    name: "tell_us_about_your_current_online_pressence",
                    title: "Tell us about your current online pressence?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "i_would_like_a_new_one",
                            text: "I would like a new one"
                        },
                        {
                            value: "i_have_an_existing_one_which_i_would_like_to_upgrade",
                            text: "I have an existing one which I would like to upgrade"
                        },
                        {
                            value: "i_have_an_existing_one_but_i_would_like_a_new_one",
                            text: "I have an existing one but I would like a new one"
                        }
                    ]
                },
                {
                    type: "text",
                    name: "your_existing_website_link",
                    visibleIf: "{tell_us_about_your_current_online_pressence} anyof ['other', 'item3', 'i_have_an_existing_one_which_i_would_like_to_upgrade', 'i_have_an_existing_one_but_i_would_like_a_new_one']",
                    title: "Your existing website link",
                    hideNumber: true
                },
                {
                    type: "radiogroup",
                    name: "do_you_have_existing_content_and_if_so_would_you_like_to_improve_it",
                    title: "Do you have existing content and if so would you like to improve it?",
                    hideNumber: true,
                    isRequired: true,
                    choices: [
                        {
                            value: "yes",
                            text: "Yes"
                        },
                        {
                            value: "no",
                            text: "No"
                        }
                    ]
                }
            ]
        },
        {
            name: "social_media_accounts",
            elements: [
                {
                    type: "multipletext",
                    name: "question14",
                    title: "Could you provide us with your social media accounts ?",
                    description: "Others option fill out with comma separated text",
                    descriptionLocation: "underTitle",
                    hideNumber: true,
                    items: [
                        {
                            name: "twitter",
                            title: "twitter"
                        },
                        {
                            name: "facebook",
                            title: "facebook"
                        },
                        {
                            name: "instagram",
                            title: "instagram"
                        },
                        {
                            name: "others_social_media_links",
                            title: "others"
                        }
                    ]
                }
            ],
            visibleIf: "{do_you_have_existing_content_and_if_so_would_you_like_to_improve_it} = 'yes'"
        },
        {
            name: "submitForm",
            elements: [
                {
                    type: "text",
                    name: "name",
                    title: "Name",
                    titleLocation: "hidden",
                    hideNumber: true,
                    isRequired: true,
                    placeHolder: "Name"
                },
                {
                    type: "text",
                    name: "email",
                    title: "Email",
                    titleLocation: "hidden",
                    hideNumber: true,
                    isRequired: true,
                    inputType: "email",
                    placeHolder: "Email"
                },
                {
                    type: "text",
                    name: "companyName",
                    title: "Company Name",
                    titleLocation: "hidden",
                    hideNumber: true,
                    placeHolder: "Company name"
                },
                {
                    type: "comment",
                    name: "additionalInfo",
                    title: "Additional Info",
                    titleLocation: "hidden",
                    hideNumber: true,
                    placeHolder: "Additional info"
                }
            ],
            title: "Send us your survey"
        }
    ],
    showCompletedPage: false,
    progressBarType: "questions"
}