import React, {useState} from 'react';
import * as Survey from "survey-react"
import { questions } from "./questions"
import styled from "styled-components"
import { colors } from "../../const/colors";
import axios from "axios"
import {ImCheckmark} from "react-icons/im";
import {ButtonGf} from "../Button/styles";


// Survey.StylesManager.applyTheme("modern");

function GFQuestionareRaw({className, topElement}) {
    const [sending , setSending] = useState(false)
    const [showSurvey, setShowSurvey] = useState(true)
    const sendDataToServer = async (survey, options) => {
        setShowSurvey(false)
        setSending(true)
        try {
            //xyybqzqq
            //mknplgzv
            const response = await axios.post("https://formspree.io/f/xyybqzqq",
                { ...survey.data }
            )
            topElement.current.scrollIntoView()
            setSending(false)
        }catch(err) {
            console.log(err)
        }
    }
    return (
        <div className={className}>
            {showSurvey && <Survey.Survey json={questions} onComplete={ sendDataToServer } />}
            { sending ? <h3>Sending data...</h3> : !showSurvey ? <div className="success" style={{display: "flex", textAlign:"center", flexDirection:"column", justifyContent: "center", alignItems: "center", width:"100%", height:"100vh"}}>
                <div>
                    <div><ImCheckmark size="75px" color={colors.greenColor} /></div>
                    <span style={{display:"inline-block", marginTop:"30px", fontSize: "2rem", fontWeight:"bold", marginBottom:"30px"}}>Thank you for survey!</span>
                    <br/>
                    <p>We are processing your survey. <br/> You can expect to hear from our team shortly. </p>
                    <ButtonGf onClick={e => {setShowSurvey(true); setSending(false)}}>Go back</ButtonGf>
                </div>
            </div> : null }
        </div>
    );
}

const GFQuestionare = styled(GFQuestionareRaw)`
  max-width: 1070px;
  margin: auto;
  padding: 30px 0;
  background: white;

  .sv-btn {
    border-radius: 3px;
    background-color: ${colors.greenColor}
  }

  textarea {
    padding: 20px;
  }

  .sv-question__title--answer {
    background-color: rgb(140 166 104 / 24%);
  }

  .sv-checkbox--checked {
    .sv-checkbox__svg {
      background-color: ${colors.greenColor}
    }
  }

  .sv-comment {
    width: 100%;
    min-height: 300px;
  }

  .success {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.77);
    z-index:200;
    > div {
      padding: 30px;
      background: white;
    }
  }
`

export default GFQuestionare;
